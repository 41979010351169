<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title>
        <v-img alt="AirTally Logo" src="../assets/AirTally_logo_Rlarge_White.png" contain min-width="80" width="80" />
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="d-flex align-start">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="7" md="6" lg="6" xl="6">
            <amplify-authenticator :authConfig="{ signInConfig: { isSignUpDisplayed: false } }">
              <amplify-sign-in></amplify-sign-in>
            </amplify-authenticator>
          </v-col>
        </v-row>
        <v-row>
          <div v-if="signedIn">
            <amplify-sign-out></amplify-sign-out>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { AmplifyEventBus } from "aws-amplify-vue";
  import { Auth } from "aws-amplify";


  export default {
    name: "SignIn",
    components: {},
    data() {
      return {
        signedIn: false,
      };
    },
    async beforeCreate() {
      try {
        await Auth.currentAuthenticatedUser();
        this.signedIn = true;
      }
      catch (err) {
        this.signedIn = false;
      }
      AmplifyEventBus.$on("authState", (info) => {
        console.log(info);
        if (info === "signedIn") {
          this.signedIn = true;
        }
        else {
          this.signedIn = false;
        }
      });
    },
  };
</script>
